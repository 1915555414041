import { FeaturedProductGrid as Component } from '@virginexperiencedays/components-v2/src/lists/FeaturedProductGrid';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { PrismicContext } from '../../types/context';
import { FeaturedProductGridType } from '../../types/slices';
import { CardVariantsFactory, typeOptions } from '../../utils/CardVariantsFactory-v2';

const FeaturedProductGrid = ({
  slice,
  context,
}: {
  slice: FeaturedProductGridType;
  context: PrismicContext;
}) => {
  const { isFaceted, isProductCardsV2 } = context;
  const { primary, items, variation, position } = slice;
  if (isFaceted) {
    return null;
  }

  const featuredProductGridProps = {
    title: primary.title,
    href: primary.cta_href && getPrismicLinkPath(primary.cta_href),
    ctaTitle: primary.cta_title,
  };
  const titleClassName = isProductCardsV2 ? 'line-clamp-3' : '';

  const optionsObj = {
    variation,
    items: items?.slice(0, 4),
    primary,
  };

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <CardVariantsFactory
        Wrapper={Component}
        wrapperProps={featuredProductGridProps}
        options={typeOptions(optionsObj, variation)}
        priority={priority}
        isProductCardsV2={isProductCardsV2}
        isProductCardWithImageCarousel
        titleClassName={titleClassName}
      />
    </Container>
  );
};

export default FeaturedProductGrid;
