import { useCallback } from 'react';
// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { HeroStyle01 as Component } from '@virginexperiencedays/components-v2/src/banners/HeroStyle/Style1';
// Utils
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import type { ImageField } from '@prismicio/types';
import type { PrismicContext } from '../../types/context';

type HeroStyle01Props = {
  slice: {
    primary: {
      title: string;
      overtitle?: string;
      body: string;
      image: ImageField;
      cta_text?: string;
      cta_href?: any;
      mobile_background_image_override?: ImageField;
      tablet_background_image_override?: ImageField;
      desktop_background_image_override?: ImageField;
    };
    slice_type: string;
  };
  context: PrismicContext;
};

const HeroStyle01 = ({ slice, context }: HeroStyle01Props) => {
  const {
    primary: {
      title,
      overtitle,
      body,
      image,
      cta_text,
      cta_href,
      mobile_background_image_override: mobile,
      tablet_background_image_override: tablet,
      desktop_background_image_override: desktop,
    },
    slice_type,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  const link = getPrismicLinkPath(cta_href) || '#';

  const handleNavigate = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: link,
      sliceType: slice_type,
      pageType,
    });
  }, [link, lastSlug, slice_type, pageType]);

  if (isFaceted) return null;

  return (
    <Container>
      <Component
        title={title}
        overTitle={overtitle}
        body={body}
        imageIsPriority={true}
        imageUrl={image.url}
        imageAlt={image.alt}
        ctaLabel={cta_text || 'Shop Now'}
        link={link}
        onClick={handleNavigate}
        backgroundImage={{
          mobile: mobile?.url,
          tablet: tablet?.url,
          desktop: desktop?.url,
        }}
      />
    </Container>
  );
};

export default HeroStyle01;
