import { ReactNode } from 'react';
import { LinkButton } from '../../navigation/LinkButton';
import { DisplayHeading } from '../../typography/DisplayHeading';

type FeaturedProductGridProps = {
  children: Array<ReactNode>;
  title?: string;
  ctaTitle?: string;
  href?: string;
};

export const FeaturedProductGrid = ({
  title,
  ctaTitle,
  href,
  children,
}: FeaturedProductGridProps) => {
  return (
    <>
      {!!title && (
        <DisplayHeading
          as="h2"
          className="text-neutral-strong leading-base mb-4 text-center text-xl xl:text-left"
        >
          {title}
        </DisplayHeading>
      )}
      <div className="grid grid-cols-2 gap-x-2 gap-y-4 xl:grid-cols-4">{children}</div>
      {ctaTitle && href && (
        <div className="mt-10 flex justify-center">
          <LinkButton size="large" variant="primary" href={href} fullWidth className="md:w-[unset]">
            {ctaTitle}
          </LinkButton>
        </div>
      )}
    </>
  );
};
